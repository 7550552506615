.navbar-brand > img {
  height: 37px;
}

::placeholder {
  color: #bbb !important;
}

.rednot--container {
  top: 40px;
  position: fixed;
}

.valuation-inputs p {
  margin-top: 5px;
}

.PlacesAutocomplete-wrapper {
  position: relative;
}

.PlacesAutocomplete-container {
  background-color: white;
  z-index: 10000;
  position: absolute;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.PlacesAutocomplete-container > div {
  padding: 5px;
  cursor: pointer;
}

.PlacesAutocomplete-suggestion-item {
  color: #333;
}

.PlacesAutocomplete-suggestion-item--active {
  background-color: #eff9ff;
}

.valuation-img {
  max-width: 100%;
  max-height: 1000px;
}

.table td {
  vertical-align: middle;
}

#ReportIndexScene th,
#ReportIndexScene td {
  font-size: 12px;
}

.not-in-deal {
  color: red;
  font-size: 18px;
}

.bursar-highlight-bg {
  background-color: rgb(255, 195, 195);
  border: red solid;
}

.bursar-highlight-title {
  color: rgb(255, 0, 0);
  font-size: 20px;
}

.bursar-highlight-bg li {
  font-size: 18px;
}

.sidebar .nav-link.active {
  background-color: #105974 !important;
}

.link {
  display: inline;
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent;
}

.link:hover {
  color: #167495;
  cursor: pointer;
  text-decoration: underline;
}
